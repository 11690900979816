import technical_issue from "../assets/images/technical_issue.jpg";
import logo_big from "../assets/images/logo_big.svg";
import logo_small from "../assets/images/logo_small.svg";

const INDUSTRIES_URL_ENDPOINT = "https://redseer.com/wp-json/wp/v2/industry";
const TAGS_URL_ENDPOINT = "https://redseer.com/wp-json/wp/v2/tags";
const USERS_URL_ENDPOINT = "https://redseer.com/wp-json/wp/v2/users";
const MEDIA_URL_ENDPOINT = "https://redseer.com/wp-json/wp/v2/media";

export const AUTO_LOGOUT_TIME = 1000 * 60 * 60 * 1 * 24 * 30; //milisecond 1 week
export const AUTO_LOGOUT_NEW_TAB_DELAY = 1000 * 2; //milisecond

// export const API_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_API_ENDPOINT
//     : "https://testapi.benchmarks.digital";

/* BENCHMARKS API ENDPOINTS */
export const API_URL = process.env.REACT_APP_API_ENDPOINT;
const USER_AUTH_ENDPOINT = `${API_URL}/authorise/login`;
const USER_VALIDATE_ENDPOINT = `${API_URL}/validate-user`;
const ANALYSIS_ENDPOINT = `${API_URL}/analysis`;
const AVAILABLE_INVITES_ENDPOINT = `${API_URL}/availableinvites`;
const SENT_INVITES_ENDPOINT = `${API_URL}/sentinvites`;
const SEND_INVITE_ENDPOINT = `${API_URL}/sendinvite/`;
const ACCEPT_INVITE_ENDPOINT = `${API_URL}/accept-invite`;
const VALIDATE_INVITE_EMAIL_ENDPOINT = `${API_URL}/validate-invite-email`;
const REPORT_DATASETS_ENDPOINT = `${API_URL}/report-datasets`;
const REPORT_PAGES_DATA_ENDPOINT = `${API_URL}/clientreportpagesdata`;
const CONTENT_ARTICLE_ENDPOINT = `https://redseer.com/wp-json/wp/v2/newsletters`;
const CONTENT_REPORT_ENDPOINT = `https://redseer.com/wp-json/wp/v2/reports`;

export const WEBFORM_TOKEN = "f810db83974e8cf8e1b0795bfdc5fcd90d8b3e6b";
/* WEBFORMS API ENDPOINTS */
export const WEBFORM_API_URL = process.env.REACT_APP_WEBFORM_ENDPOINT;
const READY_RECKONER_ENDPOINT = `${WEBFORM_API_URL}/ready-reckoner/`;
const INVESTOR_ENDPOINT = `${WEBFORM_API_URL}/investor`;
const TAG_ENDPOINT = `${WEBFORM_API_URL}/tag`;
const INVESTOR_PORTFOLIO_ENDPOINT = `${WEBFORM_API_URL}/portfolio`;
const THESIS_FILTER_ENDPOINT = `${WEBFORM_API_URL}/thesis-filter`;
const PARAMETER_GROUP_ENDPOINT = `${WEBFORM_API_URL}/param_group/`;
const VALUATION_ENDPOINT = `${WEBFORM_API_URL}/deals/`;
const EXCHANGE_RATE_DETAIL_ENDPOINT = `${WEBFORM_API_URL}/exchange-rate-detail`;

/* ROUTES URL */
// const HOME_URL = "/";
const HOME_URL = "/internet-landscape";
const SIGNIN_URL = "/signin3";
const PORTFOLIO_URL = "/portfolio";

export const CLEVERTAP_ACC_ID = process.env.REACT_APP_CLEVERTAP_ACC_ID;

const IMAGES = {
  technical_issue: technical_issue,
  logo_big: logo_big,
  logo_small: logo_small,
};

export {
  INDUSTRIES_URL_ENDPOINT,
  TAGS_URL_ENDPOINT,
  USERS_URL_ENDPOINT,
  MEDIA_URL_ENDPOINT,
  USER_AUTH_ENDPOINT,
  USER_VALIDATE_ENDPOINT,
  ANALYSIS_ENDPOINT,
  AVAILABLE_INVITES_ENDPOINT,
  SENT_INVITES_ENDPOINT,
  SEND_INVITE_ENDPOINT,
  ACCEPT_INVITE_ENDPOINT,
  VALIDATE_INVITE_EMAIL_ENDPOINT,
  REPORT_DATASETS_ENDPOINT,
  REPORT_PAGES_DATA_ENDPOINT,
  EXCHANGE_RATE_DETAIL_ENDPOINT,
  CONTENT_ARTICLE_ENDPOINT,
  CONTENT_REPORT_ENDPOINT,
  READY_RECKONER_ENDPOINT,
  HOME_URL,
  SIGNIN_URL,
  PORTFOLIO_URL,
  INVESTOR_ENDPOINT,
  TAG_ENDPOINT,
  INVESTOR_PORTFOLIO_ENDPOINT,
  THESIS_FILTER_ENDPOINT,
  PARAMETER_GROUP_ENDPOINT,
  VALUATION_ENDPOINT,
  IMAGES,
};
