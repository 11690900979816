import axios from "axios";
import moment from "moment";
import { PARAMETER_GROUP_ENDPOINT } from "../../../constants/constants";
import React, { useState } from "react";
import styled from "styled-components";
import { onCompanyProfileClickCT } from "../../../utils/clevertap";
import { media } from "../../../utils/media";

const SearchBar = ({ visibleCompanies = null }) => {
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));

  return (
    <SearchBarContainer>
      {visibleCompanies.length > 0 && visibleCompanies.length < 100 ? (
        <CompaniesTable>
  <tbody>
    {visibleCompanies.map((comp) => (
      <TableRow key={comp.company_profile_id}>
        <TableCell
          onClick={() => {
            if (IndustryDict && Boolean(IndustryDict[comp.company_profile_id])) {
              const url = `/Report3/?val=${
                IndustryDict[comp.company_profile_id].report_name
              }&key=${
                IndustryDict[comp.company_profile_id].id
              }&filter=${
                IndustryDict[comp.company_profile_id].filter
              }&filter_value=${
                IndustryDict[comp.company_profile_id].filter_value
              }`;
              window.open(url, "_blank");
            }
            comp.sector_profile_id && onCompanyProfileClickCT(
              IndustryDict[comp.company_profile_id].report_name,
              IndustryDict[comp.company_profile_id].id,
              IndustryDict[comp.sector_profile_id].report_name,
              IndustryDict[comp.sector_profile_id].id
            );
          }}
        >
          {comp.player_display_name}
        </TableCell>
      </TableRow>
    ))}
  </tbody>
</CompaniesTable>

      ) : 
      null
      }
    </SearchBarContainer>
    
  );
};

export default SearchBar;

const SearchBarContainer = styled.div`
  z-index: 100;
  background: none; /* No color */
  overflow: hidden;
   display: flex;
  justify-content: center; 
`;

const CompaniesTable = styled.table`
  border-collapse: collapse;
  background-color: #ffffff;
position: absolute;
width:20%;
  thead {
    display: none;
  }
      box-shadow: 2px 2px 4px 0px #00000040;
${media.medium`
    width:15%;

    `}
    ${media.xlarge`
        width:9%;
    
        `}${media.large`
    width:10%;

    `}
    ${media.small`
        width:20%;
        `}
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #0099fe;
  }
`;

const NoDataMessage = styled.p`
  font-size: 14px;
  color: #555;
`;
