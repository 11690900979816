import React, { useState, useEffect } from "react";
import { event } from "react-ga";
import styled, { keyframes } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { onSectorReportOpenCT } from "../../../utils/clevertap";
import ArrowIcon from "../../svg/ArrowIcon";
import HambergurMenu from "../../svg/HambergurMenu";
import { media } from "../../../utils/media";
import TickIcon from "../../svg/TickeIcon";
import { onLandScapePageOpenCT } from "../../../utils/clevertap";

const MobileSideBar = ({ isOpen = false, onCloseClick = () => null }) => {
  const location = useLocation();
  let newReporTree = JSON.parse(window.localStorage.getItem("newReporTree"));
  let subDictionary = JSON.parse(window.localStorage.getItem("subDictionary"));
  const [activeLink, setActiveLink] = useState(location.pathname);
  const navigate = useNavigate();
  const isLoggedIn = window.localStorage.getItem("loginStatus") === "true";

  let handleClick = (
    val,
    key,
    filter,
    filter_value,
    subscribed,
    industry_id
  ) => {
    onSectorReportOpenCT(val, key, subscribed);
    try {
      filter_value = filter_value.replace(/&/g, "and");
    } catch (err) {
      console.log("& absent");
    }
    window.localStorage.setItem("industry_id", industry_id);
    window.localStorage.setItem("report", val);
    navigate(
      `/Report3/?val=${val}&key=${key}&filter_value=${filter_value}&filter=${filter}&industry_id=${industry_id}`
    );
    onCloseClick();
  };

  const ParentTreeNode = ({ node }) => {
    const [subOptionOpen, setSubOptionOpen] = useState(false);

    const newNode = {
      ...node,
      children: node.level === 3 ? node.children?.[0]?.children : node.children,
    };
    const isLink = newNode.level > 2;
    const hasSubOption = newNode.children && newNode.level <= 2;

    return (
      <ReportLinkWrapper>
        <ReportLink
          onClick={() => {
            if (isLink) {
              handleClick(
                newNode.report_name,
                newNode.id,
                newNode.filter,
                newNode.filter_value,
                newNode.subscribed,
                newNode.industry
              );
            } else {
              setSubOptionOpen(!subOptionOpen);
            }
          }}
        >
          {newNode.report_name}
          {newNode.id in subDictionary ? (
            <TickeIconWrapper>
              <TickIcon width={8} height={8} color={"#FFFFFF"} />
            </TickeIconWrapper>
          ) : null}
        </ReportLink>
        {hasSubOption && (
          <IconArrow
            onIconClick={() => setSubOptionOpen(!subOptionOpen)}
            color="#A0A6A9"
            rotate={subOptionOpen ? 270 : 90}
          />
        )}

        {subOptionOpen && hasSubOption && (
          <ChildNodes>
            {newNode.children.map((child) => (
              <ParentTreeNode key={child.id} node={child} />
            ))}
          </ChildNodes>
        )}
      </ReportLinkWrapper>
    );
  };

  return (
    //Orginal logic
    <WrapperBackground>
      <PopupBody isOpen={isOpen}>
        <MobileMenu
          isOpen={true}
          onIconClick={() => onCloseClick()}
        ></MobileMenu>
        <ScrollWrapper>
          <NavLink
            isactive={activeLink === (isLoggedIn ? "/internet-landscape" : "/")}
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
              onLandScapePageOpenCT();
              onCloseClick();
            }}
          >
            Home
          </NavLink>

          {isLoggedIn ? (
            <>
              <NavLink isactive={activeLink === "/Report3/"}>Sectors</NavLink>
              <SectorListingWrapper>
                {newReporTree != null
                  ? newReporTree.map((node) => (
                      <ParentTreeNode key={node.id} node={node} />
                    ))
                  : null}
              </SectorListingWrapper>
            </>
          ) : (
            <NavLink
              isactive={activeLink === "/industries"}
              onClick={() => {
                navigate("/industries");
                onCloseClick();
              }}
            >
              Sectors
            </NavLink>
          )}
          <NavLink
            isactive={
              activeLink ===
              (isLoggedIn ? "/company-listing-new" : "/companies")
            }
            onClick={() => {
              navigate(isLoggedIn ? "/company-listing-new" : "/companies");
              onCloseClick();
            }}
          >
            Companies
          </NavLink>
          {/* <NavLink
            isactive={activeLink === "/brands"}
            onClick={() => {
              navigate("/brands");
              onCloseClick();
            }}
          >
            Brands
          </NavLink> */}
          {window.localStorage.getItem("email") &&
          ["redseerconsulting", "redcore", "agami"].some((domain) =>
            window.localStorage.getItem("email").split("@")[1]?.includes(domain)
          ) ? (
            <NavLink
            onClick={async () => {
              const email = window.localStorage.getItem("email");
              const response = await fetch(`${process.env.REACT_APP_AGAMI_API_ENDPOINT}/microsoft/login?source=benchmarks&email=${encodeURIComponent(email)}`);
              const data = await response.json();
              
              if (data.auth_url) {
                window.open(data.auth_url, "_blank");  
              } else {
                console.error('Failed to get Microsoft auth URL');
              }
            }}
            >
              Ask AI  <div style={{paddingLeft:"5%", color:"red"}}> (&beta;eta)</div>
            </NavLink>
          ) : null}
        </ScrollWrapper>
      </PopupBody>
    </WrapperBackground>
  );
};

export default MobileSideBar;

const WrapperBackground = styled.div`
  display: flex;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  background-color: #00000099;
  overflow: hidden;
`;

const PopupBody = styled.div`
  background-color: #ffffff;
  display: flex;
  width: 60%;
  padding: 7vh 0 3.5vw 0;
  min-width: 270px;
  max-height: 100%;
  overflow: hidden;
  flex-direction: column;
  font-size: 14px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ReportLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  z-index: 5;
  position: relative;
`;

const SectorListingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 15px;
`;

const ReportLink = styled.a`
  display: flex;
  width: 100%;
  height: 40px;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 40px;
  padding: 0 40px 0 10px;
  align-items: center;
  text-decoration: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    color: #ffffff !important;
    background-color: rgb(0, 153, 255);
    animation: ${fadeIn} 0.3s ease;
  }
`;

const NavLink = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  padding-left: 25px;
  align-items: center;
    white-space:nowrap;

  color: ${(props) => (props.isactive ? "#FFFFFF" : "#000000")};
  background-color: ${(props) => (props.isactive ? "#0099ff" : "#FFFFFF")};
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: #0099ff;
  }
`;

const ChildNodes = styled.div`
  z-index: -2;
  white-space: nowrap;
`;

const IconArrow = styled(ArrowIcon)`
  height: 30px;
  width: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
`;

const MobileMenu = styled(HambergurMenu)`
  position: absolute;
  top: calc(3.5vh - 16px);
  left: 3.5vw;
  height: 32px;
  width: 32px;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const TickeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  border-radius: 8px;
  margin-left: 5px;
  background-color: #0099ff;
  z-index: 10;
`;
