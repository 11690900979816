import React from 'react'
import { useState } from 'react'
import styled from 'styled-components';

const CustomForm = ({ fields, onChange, formData={} }) => {

    const handleInputChange = (event) => {
      const { name, value, checked,type } = event.target;
      const updatedFormData = { ...formData };
    
      if (type === 'email') {
        updatedFormData[name] = value;
      } else if (type === 'radio') {
        updatedFormData[name] = checked ? [value] : [];
      } else {
        updatedFormData[name] = value;
      }    

        onChange(name, updatedFormData[name]);
    };
 
    return (
      <FormWrapper>
        {fields.map((field) => (
          <div key={field.name} style={{width:'100%'}}>
            <Label htmlFor={field.type}  style = {{fontWeight:100}}>{field.name}{field.type!=='radio' && ':'}</Label>
            {field.type === 'textarea' ? (
              <TextArea
                id={field.name}
                name={field.name}
                style={field.style}
                onChange={handleInputChange}
              />
            ) : field.type==='radio'?(
              
              <div style={{ display:'inline-block', alignItems: 'center' }}>
              {field.options.map((option, index) => (
                  <div key={index} style={{ marginLeft:'60px', display:'inline-block'}}>
                      <input
                          type="radio"
                          id={`${field.name}-${option.value}`}
                          name={field.name}
                          value={option.value}
                          checked={formData[field.name] && formData[field.name].includes(option.value)}
                          onChange={handleInputChange}
                          style={{ marginRight: '5px' }}
                      />
                      <label htmlFor={`${field.name}-${option.value}`}>{option.label}</label>
                  </div>
              ))}
          </div>
          
            )
                 
            :(
              <Input
                className="custom-input"
                type={field.type}
                id={field.name}
                name={field.name}
                style={field.style}
                onChange={handleInputChange}
                defaultValue = {formData[field.name]}
                readOnly={field.allowEdit === false}
              />
            )}
          </div>
        ))}
        {/* <Button type="submit">Submit</Button> */}
      </FormWrapper>
    );
  };

export default CustomForm


const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom:0px;
`;

const Input = styled.input`
&.custom-input {
    width: 100%;
    padding: 6px 10px;
    border:1px solid #ccc;
    border-radius:50px;
  }
  /* width: 100% !important;
  padding:6px 10px !important; */
`;

const TextArea = styled.textarea`
  width: 100%;
`;

const Button = styled.button`
  width: 100%;

`;
