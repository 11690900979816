import React, { useState, useEffect } from "react";
import MyDropdown from "../../components/DropDown/dropdown";
import { BsGear, BsFillCloudArrowDownFill, BsTag } from "react-icons/bs";
import { models } from "powerbi-client";
import styled from "styled-components";
import CrossIcon from "../svg/CrossIcon";
import { media } from "../../utils/media";

const USDandFyrow = () => {
  const [moneyOption, setMoneyOption] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [yearOption, setYearOption] = useState(null);
  const cyfy_option = [{ label: "FY" }, { label: "CY" }];
  const dollar_option = [{ label: "INR" }, { label: "USD" }];
  const [currencyval, setCurrencyVal] = useState(80);
  const [currencytype, setCurrencyType] = useState("");
  const [conversiontype, setConversionType] = useState("Custom");
  const [moneymodalIsOpen, setMoneyModalIsOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  window.reports = [];

  let onCurrencyModalSubmit = () => {
    if (conversiontype === "Custom") {
      window.localStorage.setItem("conversion_type", "Custom");
      console.log(window.localStorage.getItem("conversion_type"));
      console.log(currencyval);
      window.localStorage.setItem("currency_val", currencyval);
      const currency_valuation = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency input",
          column: "Currency input",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: currencyval,
          },
        ],
      };
      const usd_selector = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency_USD_Type",
          column: "Type",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: conversiontype,
          },
        ],
      };

      for (let i = 0; i < window.reports.length; i++) {
        window.reports[i].getActivePage().then((activePage) => {
          activePage.getVisuals().then((visuals) => {
            let slicers = visuals.filter(function (visual) {
              return visual.type === "slicer";
            });
            slicers.forEach(async (slicer) => {
              const state = await slicer.getSlicerState();

              //not using state as it will change on page load.page laod code for 1st

              if (state.targets[0].column === "Type") {
                let target_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await target_slicer.setSlicerState({ filters: [usd_selector] });
              }

              if (state.targets[0].column === "Currency input") {
                let custom_usd_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await custom_usd_slicer.setSlicerState({
                  filters: [currency_valuation],
                });
              }
            });
          });
        });
      }
    } else {
      window.localStorage.setItem("conversion_type", "Dynamic");
      console.log(window.localStorage.getItem("conversion_type"));
      const usd_selector = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency_USD_Type",
          column: "Type",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: conversiontype,
          },
        ],
      };
      for (let i = 0; i < window.reports.length; i++) {
        window.reports[i].getActivePage().then((activePage) => {
          activePage.getVisuals().then((visuals) => {
            let slicers = visuals.filter(function (visual) {
              return visual.type === "slicer";
            });
            slicers.forEach(async (slicer) => {
              const state = await slicer.getSlicerState();

              //not using state as it will change on page load.page laod code for 1st

              if (state.targets[0].column === "Type") {
                let target_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await target_slicer.setSlicerState({ filters: [usd_selector] });
              }
            });
          });
        });
      }
    }
    setMoneyModalIsOpen(false);
  };

  let decCurrency = () => {
    let new_curr = currencyval - 1;
    if (new_curr < 65) {
      new_curr = 65;
    }
    setCurrencyVal(new_curr);
  };

  let incCurrency = () => {
    let new_curr = currencyval + 1;
    if (new_curr > 100) {
      new_curr = 100;
    }
    setCurrencyVal(new_curr);
  };
  const onYearSelect = (option) => {
    setYearOption(option.label);
    if (option.label === "CY") {
      window.localStorage.setItem("year", "CY");
    } else {
      window.localStorage.setItem("year", "FY");
    }

    const year_converter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Date Parameter",
        column: "Year_Type",
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: "Is",
      conditions: [
        {
          operator: "Is",
          value: window.localStorage.getItem("year"),
        },
      ],
    };
    for (let i = 0; i < window.reports.length; i++) {
      window.reports[i].getActivePage().then((activePage) => {
        activePage.getVisuals().then((visuals) => {
          let slicers = visuals.filter(function (visual) {
            return visual.type === "slicer";
          });
          slicers.forEach(async (slicer) => {
            const state = await slicer.getSlicerState();

            if (state.targets[0].column === "Year_Type") {
              let target_slicer = visuals.filter(function (visual) {
                return visual.type === "slicer" && visual.name === slicer.name;
              })[0];
              await target_slicer.setSlicerState({ filters: [year_converter] });
            }
          });
        });
      });
    }
  };

  const onMoneySelect = (option) => {
    setMoneyOption(option.label);
    if (option.label === "INR") {
      window.localStorage.setItem("currency", "INR");
      setCurrencyType("INR");
    } else {
      window.localStorage.setItem("currency", "USD");
      setCurrencyType("USD");
    }

    const money_converter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Currency Table",
        column: "Currency",
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: "Is",
      conditions: [
        {
          operator: "Is",
          value: window.localStorage.getItem("currency"),
        },
      ],
    };
    for (let i = 0; i < window.reports.length; i++) {
      window.reports[i].getActivePage().then((activePage) => {
        activePage.getVisuals().then((visuals) => {
          let slicers = visuals.filter(function (visual) {
            return visual.type === "slicer";
          });
          slicers.forEach(async (slicer) => {
            const state = await slicer.getSlicerState();

            if (state.targets[0].column === "Currency") {
              let target_slicer = visuals.filter(function (visual) {
                return visual.type === "slicer" && visual.name === slicer.name;
              })[0];
              await target_slicer.setSlicerState({
                filters: [money_converter],
              });
            }
          });
        });
      });
    }
  };

  let handleGearClick = () => {
    let val = window.localStorage.getItem("currency_val");
    let conversion_type = window.localStorage.getItem("conversion_type");
    if (conversion_type !== null) {
      setConversionType(conversion_type);
    } else {
      setConversionType("Custom");
    }
    if (val !== null) {
      console.log(val);
      setCurrencyVal(val);
    } else {
      setCurrencyVal(80);
    }
    setMoneyModalIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
    setMoneyModalIsOpen(false);
  }

  return (
    <>
      <ModelWrapper isOpen={moneymodalIsOpen}>
        <Model>
          <CloseIconWrap>
            <CrossIcon onIconClick={closeModal} />
          </CloseIconWrap>
          <ModelBody>
            <div>
              <div>Please select USD conversion rate</div>
              <FormBox>
                <RadioWrap>
                  <CustomRadio
                    checked={conversiontype === "Dynamic"}
                    onClick={() => setConversionType("Dynamic")}
                  />
                  <CustomText>Dynamic</CustomText>
                </RadioWrap>
                <CustomWrap>
                  <RadioWrap>
                    <CustomRadio
                      checked={conversiontype === "Custom"}
                      onClick={() => setConversionType("Custom")}
                    />
                    <CustomText> Custom</CustomText>
                  </RadioWrap>
                  <CountingWrapper visible={conversiontype === "Custom"}>
                    <StyledButton
                      onClick={decCurrency}
                      isPointerCursor={currencyval > 65}
                    >
                      -
                    </StyledButton>
                    <StyledInput>{currencyval}</StyledInput>
                    <StyledButton
                      onClick={incCurrency}
                      isPointerCursor={currencyval < 100}
                    >
                      +
                    </StyledButton>
                  </CountingWrapper>
                </CustomWrap>
              </FormBox>
            </div>
            <StyledSubmitButton onClick={onCurrencyModalSubmit}>
              Submit
            </StyledSubmitButton>
          </ModelBody>
        </Model>
      </ModelWrapper>
      <Wrapper>
        <MyDropdown
          options={dollar_option}
          onOptionSelect={onMoneySelect}
          prev_value={moneyOption}
          default_head={
            window.localStorage.getItem("currency")
              ? window.localStorage.getItem("currency")
              : "INR"
          }
        />
        <Geardiv>
          <BsGear onClick={() => handleGearClick()} />
        </Geardiv>

        <MyDropdown
          options={cyfy_option}
          onOptionSelect={onYearSelect}
          prev_value={yearOption}
          default_head={
            window.localStorage.getItem("year")
              ? window.localStorage.getItem("year")
              : "CY"
          }
        />
      </Wrapper>
    </>
  );
};

export default USDandFyrow;

const Geardiv = styled.div`
  border: 1px solid #b9bec1;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  ${media.small`
  padding: 2px;
  margin-left:5px;
  margin-right:5px;
  border: 0.5px solid #b9bec1;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  z-index: 1;
`;

const ModelWrapper = styled.div`
  display: ${(porps) => (porps.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  z-index: 20;
`;

const Model = styled.div`
  flex-direction: column;
  width: 450;
  max-width: 25%;
  background-color: #ffffff;
  z-index: 5;
  box-shadow: 2px 2px 4px 0px #00000040;
  border: 0.75px solid #ededed;
  border-radius: 5px;
  color: #262e40;
  cursor: default;
  ${media.small`
    height: 30vh;
    min-width: calc(100% - 10px);
    box-shadow: 1px 1px 2px 0px #00000000;
    border: 0.25px solid #ededed;
    border-radius: 2px;
  `}
`;

const ModelBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 34px);
  justify-content: space-between;
  padding: 0 30px 30px 30px;
`;

const CloseIconWrap = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 34px;
  padding: 10px 10px 20px 0px;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CustomWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
`;

const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 5px 0px;
`;

const CustomRadio = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.checked ? "#0099FF" : "#FFFFFF")};
  border-radius: 50%;
  border: 2px solid #ddd;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
`;

const CustomText = styled.span`
  font-size: 16px;
  padding-left: 8px;
`;

const CountingWrapper = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #b9bec1;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  margin: 0px 5px;
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  justify-content: space-around;
  min-width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  cursor: ${(props) => (props.isPointerCursor ? "pointer" : "not-allowed")};
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 60px);
  height: 30px;
  border-left: 1px solid #b9bec1;
  border-right: 1px solid #b9bec1;
  background-color: #ffffff;
  color: black;
`;

const StyledSubmitButton = styled.button`
  color: white;
  background-color: #0099ff;
  margin-top: 10px;
  border-radius: 6px;
  border: 0px;
  height: 30px;
  cursor: pointer;
`;
